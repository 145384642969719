<template>
    <a-card>
        <a-page-header
            title="人群包数据源"
        />

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.id"
            :pagination='false'
            class="mt-20"
        >
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <base-button
                    :type="'link'"
                    :title="'人群包详情'"
                    @onClickBtn="jumpDetailPage(record)"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
import filters from "@/mirror-modules/filters.js";

export default {
    data() {
        return {
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '数据源',
                    dataIndex: 'sourceName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '同步率',
                    dataIndex: 'syncRate',
                    customRender(text) {
                        return (text ?? '') !== '' ? `${text}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '匹配类型',
                    dataIndex: 'matchTypeName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '数据量',
                    dataIndex: 'audienceTotal',
                    customRender(text) {
                        return (text ?? '') !== '' ? `${filters.changeNum(text, 2, true)}` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
                {
                    align: 'center',
                    title: '数据源最新更新时间',
                    dataIndex: 'mtime',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            let params = {
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.materialManage.getCrowdPackDataSourceList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        jumpDetailPage(record) {
            this.$router.push({
                path: `/crowdPackDataSource/detail`,
                query: {
                    id: record.id,
                    sourceName: record.sourceName
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}

.mt-20 {
    margin-top: 20px;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.link {
    text-decoration: underline;
}
</style>